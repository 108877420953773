import URLPattern from "url-pattern";
import * as qs from "qs";

const buildURL = (base: string, params?: Record<string, unknown>): string =>
  params ? `${base}?${qs.stringify(params)}` : base;

type QueryParams = Record<string, unknown>;

class Route<Params> {
  private pattern: URLPattern;
  private matcher: URLPattern;

  constructor(patternString: string) {
    this.pattern = new URLPattern(patternString);
    this.matcher = new URLPattern(`${patternString}(/*)`);
  }

  path(params?: Params, query?: QueryParams): string {
    const url = this.pattern.stringify(params);
    return buildURL(url, query);
  }

  navigate(params?: Params, query?: QueryParams): void {
    const url = this.path(params, query);
    window.location.assign(url);
  }

  matches(url: string): boolean {
    return !!this.matcher.match(url);
  }
}

interface ProgramParams {
  programId: number | string;
}

type RouteCollection = Record<string, Route<unknown>>;

export const PROGRAMS_OVERVIEW = new Route("/admin/programs/overview");
export const ADMIN_DASHBOARD = new Route<ProgramParams>(
  "/admin/programs/:programId/dashboard",
);
export const ADMIN_SETTINGS = new Route<ProgramParams>(
  "/admin/programs/:programId/configuration",
);
export const ADMIN_COMPETENCIES = new Route<ProgramParams>(
  "/admin/programs/:programId/competencies",
);
export const ADMIN_MEMBERS = new Route<ProgramParams>(
  "/admin/programs/:programId/members",
);
export const ADMIN_TIMEFRAMES = new Route<ProgramParams>(
  "/admin/programs/:programId/timeframes",
);
export const ADMIN_USER_ACTIVITY = new Route<ProgramParams>(
  "/admin/programs/:programId/user_activity",
);
export const ADMIN_ACHIEVEMENT_ISSUER = new Route<ProgramParams>(
  "/admin/programs/:programId/configuration/achievement_issuer",
);
export const PROGRAM_DASHBOARD = new Route<ProgramParams>(
  "/programs/:programId/homepage",
);
export const PROGRAM_ONBOARDING = new Route<ProgramParams>(
  "/programs/:programId/onboarding",
);
export const PROGRAM_WELCOME = new Route<ProgramParams>(
  "/programs/:programId/welcome",
);
export const PROGRAM_ABOUT = new Route<ProgramParams>(
  "/programs/:programId/about",
);
export const PROGRAM_OPPORTUNITIES = new Route<ProgramParams>(
  "/programs/:programId/opportunities",
);
export const PROGRAM_OPPORTUNITY_PLANNER = new Route<ProgramParams>(
  "/programs/:programId/opportunities/planner",
);
export const PROGRAM_LEVEL_UPS = new Route<ProgramParams>(
  "/programs/:programId/level_up_requests",
);
export const LOGOUT = new Route("/logout");

export const PROGRAM_BADGES = new Route<ProgramParams>(
  "/programs/:programId/badges",
);

interface BadgeParams {
  programId: number | string;
  badgeId: number | string;
}
export const ADMIN_BADGES = new Route<ProgramParams>(
  "/admin/programs/:programId/badges",
);
export const ADMIN_BADGE_DETAIL = new Route<BadgeParams>(
  "/admin/programs/:programId/badges/:badgeId",
);
export const ADMIN_BADGE_EDIT = new Route<BadgeParams>(
  "/admin/programs/:programId/badges/:badgeId/edit",
);
export const ADMIN_BADGE_QUICK_AWARD = new Route<BadgeParams>(
  "/admin/programs/:programId/badges/:badgeId/quick_award",
);

const ADMIN_ROUTE_BASE = "/admin/programs/:programId";
const STUDENT_ROUTE_BASE = "/programs/:programId";

export const STUDENT_NOTIFICATION_ROUTES: RouteCollection = {
  index: new Route<ProgramParams>(`${STUDENT_ROUTE_BASE}/notifications`),
  detail: new Route<ExerciseParams>(`${STUDENT_ROUTE_BASE}/notifications/:notificationId`),
};

export const ADMIN_ANNOUNCEMENT_ROUTES: RouteCollection = {
  index: new Route<ProgramParams>(`${ADMIN_ROUTE_BASE}/announcements`),
  detail: new Route<ExerciseParams>(`${ADMIN_ROUTE_BASE}/announcements/:announcementId`),
};

interface ExerciseParams extends ProgramParams {
  exerciseId: number;
}
export const ADMIN_EXERCISE_ROUTES: RouteCollection = {
  index: new Route<ProgramParams>(`${ADMIN_ROUTE_BASE}/exercises`),
  detail: new Route<ExerciseParams>(`${ADMIN_ROUTE_BASE}/exercises/:exerciseId`),
  edit: new Route<ExerciseParams>(`${ADMIN_ROUTE_BASE}/exercises/:exerciseId/edit`),
};

interface CareerParams extends ProgramParams {
  careerId: number;
}
export const ADMIN_CAREERS_ROUTES: RouteCollection = {
  index: new Route<ProgramParams>(`${ADMIN_ROUTE_BASE}/careers`),
  detail: new Route<CareerParams>(`${ADMIN_ROUTE_BASE}/careers/:careerId`),
  edit: new Route<CareerParams>(`${ADMIN_ROUTE_BASE}/careers/:careerId/edit`),
};

export const ADMIN_OPPORTUNITIES_ROUTES: RouteCollection = {
  index: new Route<ProgramParams>(`${ADMIN_ROUTE_BASE}/opportunities`),
  detail: new Route<CareerParams>(`${ADMIN_ROUTE_BASE}/opportunities/:opportunityId`),
  edit: new Route<CareerParams>(`${ADMIN_ROUTE_BASE}/opportunities/:opportunityId/edit`),
};

export const STUDENT_CAREERS_ROUTES: RouteCollection = {
  index: new Route<ProgramParams>(`${STUDENT_ROUTE_BASE}/careers`),
  detail: new Route<CareerParams>(`${STUDENT_ROUTE_BASE}/careers/:careerId`),
};
export const STUDENT_COMPETENCIES_ROUTES: RouteCollection = {
  index: new Route<ProgramParams>(`${STUDENT_ROUTE_BASE}/competencies`),
  detail: new Route<CareerParams>(`${STUDENT_ROUTE_BASE}/competencies/:competencyId`),
};
export const STUDENT_COMPETENCY_CATEGORIES_ROUTES: RouteCollection = {
  index: new Route<ProgramParams>(`${STUDENT_ROUTE_BASE}/competency_categories`),
  detail: new Route<CareerParams>(`${STUDENT_ROUTE_BASE}/competency_categories/:categoryId`),
};

export const ADMIN_COHORT_ROUTES: RouteCollection = {
  index: new Route<ProgramParams>(`${ADMIN_ROUTE_BASE}/cohorts`),
  create: new Route<CompetencyParams>(`${ADMIN_ROUTE_BASE}/cohorts/create`),
  detail: new Route<CompetencyParams>(`${ADMIN_ROUTE_BASE}/cohorts/:cohortId`),
  edit: new Route<CompetencyParams>(`${ADMIN_ROUTE_BASE}/cohorts/:cohortId/edit`),
};

export const STUDENT_BADGE_DETAIL = new Route<BadgeParams>(
  "/programs/:programId/badges/:badgeId",
);

interface OnboardingSlideParams {
  programId: number | string;
  slideId: number | string;
}
export const ADMIN_ONBOARDING_SLIDES = new Route<ProgramParams>(
  "/admin/programs/:programId/onboarding_slides",
);
export const ADMIN_ONBOARDING_SLIDE_DETAIL = new Route<OnboardingSlideParams>(
  "/admin/programs/:programId/onboarding_slides/:slideId",
);
export const ADMIN_ONBOARDING_SLIDE_EDIT = new Route<OnboardingSlideParams>(
  "/admin/programs/:programId/onboarding_slides/:slideId/edit",
);

export const ADMIN_ANALYTICS = new Route<ProgramParams>(
  "/admin/programs/:programId/analytics",
);

interface LevelRequestParams {
  programId: number | string;
  levelRequestId: number | string;
}

export const STUDENT_LEVEL_REQUESTS = new Route<ProgramParams>(
  "/programs/:programId/level_up_requests",
);
export const STUDENT_LEVEL_REQUEST_DETAIL = new Route<LevelRequestParams>(
  "/programs/:programId/level-requests/:levelRequestId",
);
export const STUDENT_LEVEL_REQUEST_EDIT = new Route<LevelRequestParams>(
  "/programs/:programId/level-requests/:levelRequestId/edit",
);
export const STUDENT_INITIAL_ASSESSMENT_EDIT = new Route<LevelRequestParams>(
  "/programs/:programId/initial-assessment/:levelRequestId/edit",
);

export const ADMIN_LEVEL_REQUEST_ROUTES: RouteCollection = {
  index: new Route<ProgramParams>(`${ADMIN_ROUTE_BASE}/level_requests`),
  detail: new Route<LevelRequestParams>(`${ADMIN_ROUTE_BASE}/level_requests/:levelRequestId`),
  review: new Route<LevelRequestParams>(`${ADMIN_ROUTE_BASE}/level_requests/:levelRequestId/review`),
};

interface AchievementParams {
  achievementId: string;
}

export const PUBLIC_ACHIEVEMENT_DETAIL = new Route<AchievementParams>(
  "/achievements/:achievementId",
);

interface CompetencyCategoryParams extends ProgramParams {
  competencyCategoryId: number;
}

export const STUDENT_COMPTENCIES_ROUTES: RouteCollection = {
  index: new Route<ProgramParams>(`${STUDENT_ROUTE_BASE}/competencies`),
  detail: new Route<CompetencyParams>(`${STUDENT_ROUTE_BASE}/competencies/:competencyId`),
  categoryDetail: new Route<CompetencyCategoryParams>(`${STUDENT_ROUTE_BASE}/competency_categories/:competencyCategoryId`),
};

interface CompetencyParams extends ProgramParams {
  competencyId: number | string;
}
export const ADMIN_COMPETENCY_ROUTES = {
  index: new Route<ProgramParams>(`${ADMIN_ROUTE_BASE}/competencies`),
  detail: new Route<CompetencyParams>(`${ADMIN_ROUTE_BASE}/competencies/:competencyId`),
  edit: new Route<CompetencyParams>(`${ADMIN_ROUTE_BASE}/competencies/:competencyId/edit`),
};

interface DimensionParams extends ProgramParams {
  dimensionId: number | string;
}
export const ADMIN_DIMENSION_ROUTES = {
  detail: new Route<DimensionParams>(
    `${ADMIN_ROUTE_BASE}/dimensions/:dimensionId`,
  ),
};

interface LevelParams extends ProgramParams {
  levelId: number | string;
}
export const ADMIN_LEVEL_ROUTES = {
  detail: new Route<LevelParams>(`${ADMIN_ROUTE_BASE}/levels/:levelId`),
  edit: new Route<LevelParams>(`${ADMIN_ROUTE_BASE}/levels/:levelId/edit`),
};

export const ADMIN_EXTERNAL_ITEM_REFERENCE_ROUTES = {
  list: new Route<ProgramParams>(
    `${ADMIN_ROUTE_BASE}/external_item_references`,
  ),
};

export const ADMIN_ENROLLMENT_RULE_ROUTES = {
  list: new Route<ProgramParams>(`${ADMIN_ROUTE_BASE}/enrollment_rules`),
};
